.mainDiv {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

}



.webTitleDiv {
    display: flex;
    flex-direction: column;

}

.cartLogo {
    width: 35px;
    height: 60px;
    margin-top: 15px;
    margin-left: 10px;
    mix-blend-mode: multiply;
}

.btn {
    width: 150px;
    font-size: 20px;
    font-family: "Times New Roman", Times, 'rubik';
    background-color: #adcbd3;
    border: 1px solid black;
    margin-top: 200px;


}

.btnWeb {
    width: 150px;
    font-size: 18px;
    font-family: "Times New Roman", Times, 'rubik';
    background-color: #adcbd3;
    border: 1px solid black;
    margin: 10px;
    margin-top: 20px;
}

.mediaBtn {
    font-family: "Times New Roman", Times, 'rubik';
    width: 70px;
    background-color: black;
    color: white;
    border-radius: 45%;
    height: 30px
}

.logoDiv {
    margin: 0 auto;
}

.logo {
    width: 200px;
    height: 100px;
    mix-blend-mode: multiply;

}

.title {
    font-size: 50px;
    margin-bottom: 0px;
    font-family: "Times New Roman", Times, serif;
}

.bar {
    display: flex;
    justify-content: space-between;
    width: 800px;
    margin-right: 480px;
}

.bar2 {
    display: flex;
    justify-content: space-between;
    width: 655px;
    margin-left: 7%;

}

.bar3 {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    /* position: fixed; */
    top: 0;
}


.photo {
    width: 350px;
    height: 220px;
    margin: 5px;
    mix-blend-mode: multiply;
}

.subtitle {
    color: rgb(10, 153, 236);
    font-size: 250%;
    width: 80%;
    margin: 0 auto;
     margin-top: 10px;
    font-family: "Times New Roman", Times, 'rubik';
}

.productList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 10px;




}

.name {
    font-size: 30PX;
    margin-top: 40px;
    font-family: "Times New Roman", Times, serif;

}

.btn1 {
    width: 80%;
    height: 80%;
    margin-bottom: 20px;
    margin-top: '50px';
    font-size: 150%;
    font-family: "Times New Roman", Times, serif;
    background-color: white;
    border: 1px solid black;
    color: black;
    border-radius: 20%;

}


.btn2 {
    width: 60%;
    height: 80%;
    margin-bottom: 20px;
    margin-top: '50px';
    font-size: 100%;
    font-family: "Times New Roman", Times, serif;
    background-color: white;
    border: 1px solid black;
    color: black;
    border-radius: 20%;

}

.cont {
    background-color: #d2ebf1;

}

.btn3 {
    width: 50%;
    height: 20px;
    margin-bottom: 20px;
    margin-top: '50px';
    font-size: 100%;
    font-family: "Times New Roman", Times, serif;
    background-color: white;
    border: 1px solid black;
    color: black;
    border-radius: 20%;

}

.btn4 {
    width: 80%;
    height: 50px;
    margin-bottom: 20px;
    margin-top: '50px';
    font-size: 100%;
    font-family: "Times New Roman", Times, serif;
    background-color: white;
    border: 1px solid black;
    color: black;
    border-radius: 20%;

}

.btn5 {
    width: 18%;
    height: 30px;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 100%;
    font-family: "Times New Roman", Times, serif;
    background-color: white;
    border: 1px solid black;
    color: black;
    border-radius: 20%;

}

.divNum {
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.video {
    width: 350px;
    height: 260px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;

}

.saleDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 82%;
    margin: 0 auto;
    margin-top: 20px;
    background-color: white;
    padding: 10px;

}

.mediaSaleDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px auto;
    padding: 10px;
}

.mediaSupriseDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px auto;
    width: 82%;
    border: 2px solid black;
    background-color: #adcbd3;
    padding: 10px;
}

.chainMedia {
    display: none;

}

.braceletsMedia {
    display: none;
}

.chosenChainMedia {
    display: none;
}

.chosenBraceletMedia {
    display: none;
}


.mediaBar {
    display: none;

}

.mediaMainDiv {
    display: none;
    width: 100%;
}

@media (max-width:768px) {

    .webTitleDiv,
    .mainDiv,
    .chainWeb,
    .braceletsWeb,
    .chosenChainWeb,
    .chosenBraceletWeb {
        display: none;
    }

    .title {
        font-size: 30px;
    }


    .burgerBtn,
    .mediaBar,
    .mediaMainDiv,
    .chainMedia,
    .braceletsMedia,
    .chosenChainMedia,
    .chosenBraceletMedia {
        display: block;
    }


}




.mediaCartLogo {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    margin-right: 10px;
    mix-blend-mode: multiply;
}


.mediaLogo {
    width: 130px;
    height: 70px;
    margin-top: 10px;
    mix-blend-mode: multiply;
}


.humburgerBtn {
    width: 80%;
    font-size: 15px;
    background-color: black;
    border: none;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 5px;
}

.humburger:active {
    text-decoration: underline;
}

.menu-container {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%; 
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
  }
  

  .menu-open {
    transform: translateX(0%);
  }
  

  .menuDiv {
    padding-top: 20%;
    background-color: black;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%; 
    transition: transform 0.3s ease-in-out;
  }

 

.supriseBtn {
    width: 220px;
    height: 50px;
    font-size: 30px;
    border-radius: 20px;
    background-color: #adcbd3;
    font-family: "Times New Roman", Times, serif;
}

.cartDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center
}

.chosenProductDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;
    margin-top: 20px;
    border: 2px solid#d2ebf1;
    ;
    background-color: white;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 30px;

}

.mediaChain {
    display: none;
}

.homePageProduct {
    display: flex;
    flex-direction: column;
    border: 1px solid #adcbd3;
    width: 17%;
    margin: 10px 0 auto;
}

.mediaHomePageProduct {
    display: flex;
    flex-direction: column;
    border: 1px solid #adcbd3;
    width: 45%;
    margin: 0 auto;
    margin-top: 10px;
}

.inp {
    margin: 0 auto;
    margin-top: 20px;
    width: 33%;
    height: 15%;
    font-size: 100%;
    text-align: center;
}

.inp2 {
    color: white;
    background-color: white;
    border: none;
    margin-top: 10px;
    font-size: 90%;
    text-align: center;
}

.slider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}



.right-arrow {
    position: absolute;
    /* top: 50%; */
    right: 0px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
    left: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active {
    position: relative;
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}

.photo-gallery {
    width: 100%;
    height: 700px;
    /* Adjust the height as needed */
    overflow: hidden;
}

.scrollable-container {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.photo-wrapper {
    flex: 0 0 auto;
    width: 80%;
    height: 100%;
}

.photo-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sale {
    text-decoration: line-through;
    margin-right: 10px;
}

.homePageBar {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: rgb(249, 248, 248);
    margin-bottom: 10px;
}

.homePageP {
    font-size: 130%;
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: left;
    margin-left: 10px;
    margin-top: 0px;
}

.homePageP2 {
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: left;
    margin-left: 10px;
    margin-top: 0px;
    color: grey;
}

.popup {
    display: none;
    position: fixed;
    top: 10;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.teamsDiv {
    display: flex;
    justify-content: right;
}

.loading {
    width: 100%;
    margin: 0 auto;
    padding-top: 80px;
    height: 250px;
}


.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.fullscreen-photo {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

ul {
    list-style-type: none;
    margin-top: 0px;
}

li {
    margin: 0 auto;
}

.timer {
    text-align: center;
    font-size: 2rem;
    margin-top: 50px;
  }
  
  .time {
    width: 30%;
    display: flex;
    margin: 0 auto;

  }

  .countdown-timer{
    width: 100%;
    background-color: black;
    color: white;
    padding: 5px;   
  }

  .timerTitle{
    margin-bottom:5px;
    margin-top: 0px;
    font-size:18px;
    font-family:"Times, New Roman, Times serif" 
  }

  .timerP1{
    margin-bottom:0px;
    margin-top: 0px;
    font-size:15px;
  }

  .timerP2{
    margin-top: 0px;
    font-size:10px;
  }